var bannerContainer = $('.slider__full'),
    bannerItem = bannerContainer.find('.banner-item'),
    bannerCheveron = $('<div class="banner-chevron"><div class="container"><div class="row"><div class="banner-wrapper"><div class="items cheveron"><i class="fa fa-chevron-down"></i></div></div></div></div></div>'),
    bannerPager = $('<div class="pager"><div class="pager-container"><div class="pager-wrapper"><div class="items"></div></div></div></div>'),
    bannerTimeOut = 5,
    bannerSlideTime = 2.5,
    maxInnerHeight,
    maxHeight,
    resizeId = null,
    _winH = $(window).height(),
    currentBanner = 0,
    backgrounds = [];

$(function(){

    if(bannerItem.length > 0) {
      bannerContainer.append(bannerCheveron);
        if(bannerContainer.hasClass('clickable')){

          $('.cheveron').addClass('clickable');

            this$ = $('.cheveron.clickable');


            this$.click(function(){
              var scroll = this$.offset().top;

              $("html, body").animate({ scrollTop: scroll }, 1000);
            });

        }

        bannerContainer.append(bannerPager);

        $(window).bind('resize load', function(){
			resizeId = setTimeout(bannerResize, 500);
		});

        bannerItem.each(function(i){
            if($('.banner-bg',this).css("background-image") == 'none') {
                backgrounds[i] = $('.banner-bg',this).data("backgrounds").split(',')
            }
            else {
                backgrounds[i] = $('.banner-bg',this).css("background-image").split(',')
            }
            bannerPager.find('.items').append('<a href="" class="pager-item"></a>');
        });
        console.log(backgrounds);
        bannerResize();


        $('a', bannerPager).bind("click", function(){
            TweenMax.killTweensOf(nextBanner);
            nextBanner($(this).index());
            return false;
        });

        $('a', bannerPager).eq(currentBanner).addClass('current-slide');

        TweenLite.set(bannerItem.filter(':gt(0)'), {autoAlpha:0});
        TweenMax.delayedCall(bannerTimeOut, nextBanner);
    }
});

function bannerResize() {

	_winH = $(window).height();

	console.log('bannerResize');
	     bannerItem.each(function(i){
            maxInnerHeight = maxInnerHeight > $('.banner-wrap .row', this).height() ? maxInnerHeight : $('.banner-wrap .row', this).height();
            maxHeight = maxHeight > $(this).height() ? maxHeight : $(this).height();
	            $(this).css({
	                position: "absolute",
	                top: 0,
	                width: "100%"
	            });
                $('.banner-bg',this).css({
                    backgroundImage : function() {
                        console.log(i);
                        if(matchMedia("(min-width:1200px)").matches) {
                            console.log('Large screen');
                            return backgrounds[i][0];
                        }
                        else if(matchMedia("(min-width:960px)").matches) {
                            console.log('Medium screen');
                            return backgrounds[i][1];
                        }
                        console.log('Small screen');
                        return '';
                  }
             });
        });

	    //if(!$(bannerContainer).hasClass("banner-txt")) {

	  //  }

	 if(!$(bannerContainer).hasClass("banner-txt")) {

        $(bannerContainer).css({
            height: ($(bannerContainer).hasClass("full-width") ? _winH : maxHeight),
            position: "absolute",
            left: 0, top: 0,
            width: "100%"
        }).parent().css({paddingTop: ($(bannerContainer).hasClass("full-width") ? _winH : maxHeight)});
        $('.banner-wrap .row', bannerContainer).css({
            height: ($(bannerContainer).hasClass("full-width") ? _winH : maxInnerHeight)
        });
    }
    else {
        if(matchMedia("(max-width:960px)").matches) {
            var slideHeight = 0;
            bannerItem.each(function(){
                slideHeight = slideHeight > $(this).outerHeight() ? slideHeight : $(this).outerHeight();
                console.log(slideHeight);
                console.log($(this));
            });

            console.log(slideHeight);
            slideHeight = slideHeight  + bannerPager.outerHeight();
            console.log(slideHeight);
            console.log(bannerPager.outerHeight());
            $(bannerContainer).css({
                height: slideHeight,
                position: "",
                left: '', top: '',
                width: ''
            }).parent().css({paddingTop: ''});
            $('.banner-wrap .row', bannerContainer).css({
                height: ''
            });
        }
        else {
          $(bannerContainer).css({
              height: ($(bannerContainer).hasClass("banner-txt") ? _winH : maxHeight),
              position: "absolute",
              left: 0, top: 0,
              width: "100%"
          }).parent().css({paddingTop: ($(bannerContainer).hasClass("banner-txt") ? _winH-$('header').outerHeight() : maxHeight)});
          $('.banner-wrap .row', bannerContainer).css({
              height: ($(bannerContainer).hasClass("banner-txt") ? _winH : maxInnerHeight)
          });
        }

    }

        $('a.banner-pager').css({
        	top: (maxHeight / 2)
        });
}

function nextBanner(index){

    TweenLite.to( bannerItem.eq(currentBanner), bannerSlideTime, {autoAlpha:0} ); //fade out the old slide
    $('a', bannerPager).removeClass('current-slide');

    if(index != undefined) {
        currentBanner = index;
    } else {
        currentBanner = ++currentBanner % bannerItem.length;
    }

    TweenLite.to( bannerItem.eq(currentBanner), bannerSlideTime, {autoAlpha:1} );

    $('a', bannerPager).eq(currentBanner).addClass('current-slide');

    TweenMax.delayedCall(bannerTimeOut, nextBanner);

}
