//var $ = require("jquery");
//require("TweenMax");
//require("./voyou.slideshow.min.js");

require("./common.js");

if($('.slider__full').length>0){
  require("./banner.js");
}




$(function(){
  if($('.slider__full').length==0){
    $("#banner").slideShow('.banner-item');
  }
  /*if($('.block-quote').length!=0){

    $('.testimonial-wrap').slideShow('.testimonial-slide');

  }*/

});
